/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Formik } from 'formik'
import Img from 'gatsby-image'

import React, { useState } from 'react'
import { Link, navigate } from 'gatsby'
import { InputField, CheckboxField, SelectField } from '../../../gatsby-theme-landmarks-developer/src/components/Fields/Fields'
import { Error } from '../../../gatsby-theme-landmarks-core/src/components/Error/Error'
import { Button, Spinner } from 'theme-ui'
import logo from '../images/site/Scala-Center-horizontal.png'
import Header from '../sections/Header/Header'
import Footer from '../sections/Footer/Footer'

import {
    Helmet,
    Row,
    Col,
    Card,
    Container,
    A,
    H1,
    H2,
    H3,
    Grid,
    Small,
    Text,
    MoreDetails
} from 'gatsby-theme-landmarks-core'

const Contact = props => {
  const { data } = props
  const {
    hartaDesktop,
    hartaMobile,
    scalafrunzisului
  } = data  
  
  const [globalError, setGlobalError] = useState('')
    const validate = values => {
      const errors = {}

      if (!values.nume) {
        errors.nume = 'Va rugam sa va introduceti numele'
      }
      if (!values.telefon) {
        errors.telefon = 'Va rugam sa introduceti numarul de telefon'
      }
      // else if (!/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/i.test(values.telefon)) {
      //   errors.telefon = `Numarul de telefon este invalid`
      // }
      if (!values.email) {
        errors.email = 'Va rugam sa introduceti adresa de email'
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Adresa de email este invalida'
      }
      if (!values.mesaj) {
        errors.mesaj = 'Ati uitat sa introduceti mesajul'
      }
      if (!values.acordPolitica) {
        errors.acordPolitica = true
      }
      return errors
    }
    return (
      <>
        <Helmet>
            <title>
            Contact
            </title>
          <meta
            name="title"
            content="Contact | Scala Frunzisului"
          />
          <meta
            name="description"
            content="Contact"
          />
        </Helmet>
        <Header
         logoSrc={logo}
          logoAlt="Scala Frunzisului"
        />

          <Container sx={{ 
            position: 'relative',
            mb:'200px'
            }}>
            <div
              sx={{
                width: ['100vw', 'auto', 'auto'],
                position: ['initial', 'initial', 'absolute'],
                color: 'secondary',
                backgroundColor: 'primary',
                right: 5,
                top: 1,
                zIndex: 2,
                width: [null, null, '42%'],
                p: [null, null, 4],
                borderColor: 'mediumGrayOpaque',
                borderWidth: [0, 0, '1px'],
                borderStyle: 'solid',
                px: 3,
                pt:'100px',
                mt:[0,'100px']
              }}
            >
            <H1>Bun venit </H1>
            <p>Ne pare bine de cunoștință! Completează formularul de mai jos și un reprezentant al echipei noastre de vânzări te va contacta pentru o discuție. </p>
            <p>Strada Frunzișului 91, Cluj-Napoca 400664  </p>

<Formik
      initialValues={{
        nume: '',
        telefon: '',
        email: '',
        mesaj: '',
        source: null,
        acordPolitica: false,
        acordInformari: false,
        day: new Date().getDate().toString() + "-" + (new Date().getMonth() + 1).toString() + "-" + new Date().getFullYear().toString()
      }}
      validate={validate}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        fetch(`/.netlify/functions/submit_lead_update`, {
          method: 'POST',
          body: JSON.stringify({
            data: values
          })
        })
          .then(res => {
            if (res.status === 200) {
              navigate('/thank-you')
            } else {
              setGlobalError('Something went wrong')
              setSubmitting(false)
            }
            console.log(res)
          })
          .catch(err => {
            console.log(err)
            setGlobalError(err)
            setSubmitting(false)
          })
      }}
    >
      {props => (
        <form onSubmit={props.handleSubmit} onReset={props.handleReset}>
          <InputField name="nume" label="Nume" />
          <InputField type="tel" name="telefon" label="Telefon" />
          <InputField type="email" name="email" label="Email" />
          <SelectField name="sursa" label="De unde ati aflat de noi?">
            <option value="">-</option>
            <option value="facebook">Facebook</option>
            <option value="google">Google</option>
            <option value="prieten">Prieten</option>
            <option value="banner">Banner</option>
          </SelectField>
          <InputField as="textarea" name="mesaj" label="Mesaj" />
          <CheckboxField
            name="acordPolitica"
            label={
              <>
                am luat la cunostinta{' '}
                <Link to="politica-confidentialitate-gdpr" sx={{
                  color:'white'
                }}>
                  Politica de Confidenţialitate şi Protecţia Datelor cu Caracter
                  Personal
                </Link>
              </>
            }
          />
          <CheckboxField
            name="acordInformari"
            label="sunt de acord sa primesc informari comerciale
            referitoare la proiectul Scala Frunzisului"
          />
          {globalError && <Error sx={{ my: 3 }}>{globalError}</Error>}
          <Button
            sx={{
              variant: props.isSubmitting && 'buttons.disabled',
              my: 4
            }}
            disabled={props.isSubmitting}
            type="submit"
          >
            {props.isSubmitting ? (
              <>
                <Spinner sx={{ variant: 'styles.spinner' }} />
                Se trimite
              </>
            ) : (
              'Trimite'
            )}
          </Button>
        </form>
      )}
    </Formik>

            </div>

            <Img
              sx={{
                mr: [0, 7],
                pt:4,
                width: ['100vw', 'auto', 'auto'],
              }}
              fluid={[
                hartaMobile.childImageSharp.fluid,
                {
                  ...hartaDesktop.childImageSharp.fluid,
                  media: `(min-width: 1280px)`
                }
              ]}
            />
            <br></br>
          <Img
            sx={{
              mr: [0, 7],
              width: ['100vw', 'auto', '50%'],
            }}
            fluid={[
              scalafrunzisului.childImageSharp.fluid
            ]}
          />
          </Container>
       <Footer/>
      </>
    )
  }


export const query = graphql`
  {
    hartaDesktop: file(name: { eq: "harta-scala" }) {
      childImageSharp {
        fluid(maxWidth: 1923, maxHeight: 1080, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          presentationHeight
        }
      }
    }
    hartaMobile: file(name: { eq: "harta-mobile" }) {
      childImageSharp {
        fluid(maxWidth: 1923, maxHeight: 1080, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          presentationHeight
        }
      }
    }
      scalafrunzisului: file(name: { eq: "scala-frunzisului" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          presentationHeight
        }
      }
    }
  }
`

  export default Contact

